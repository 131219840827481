html.isIE {

  h1, p {
    font-weight: 300;
  }

  a:focus,
  a:active { outline: none; outline: 0; }

  .question {

    ul {

      li {
        font-weight: 300;
      }
    }
  }

  span.audio {

    audio {
      vertical-align: middle;
    }
  }

  .slide {

    ul {

      li {
        font-weight: 300;
        list-style: disc;
      }
    }

    table.table-striped {
      font-weight: 300;
    }

    .toggleList {

      li {
        list-style: none;
      }
    }
  }

  .survey {
    font-weight: 300;

    .row {

      label {
        font-weight: 300;
      }
    }
  }

  .h-slider {

    & + .row {
      font-weight: 300;
    }
  }
}
