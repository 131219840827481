html.isIE h1,
html.isIE p {
  font-weight: 300;
}
html.isIE a:focus,
html.isIE a:active {
  outline: none;
  outline: 0;
}
html.isIE .question ul li {
  font-weight: 300;
}
html.isIE span.audio audio {
  vertical-align: middle;
}
html.isIE .slide ul li {
  font-weight: 300;
  list-style: disc;
}
html.isIE .slide table.table-striped {
  font-weight: 300;
}
html.isIE .slide .toggleList li {
  list-style: none;
}
html.isIE .survey {
  font-weight: 300;
}
html.isIE .survey .row label {
  font-weight: 300;
}
html.isIE .h-slider + .row {
  font-weight: 300;
}
